import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import axios from "axios";
import HomePage from "./pages/homePage";

function App() {
  // const [locationData, setLocation] = useState("US");

  // // Define fetchLocation before useEffect
  // const [ipInfo, setIpInfo] = useState(null);

  // useEffect(() => {
  //   const fetchIpInfo = async () => {
  //     try {
  //       const response = await axios.get("https://ipapi.co/json/");
  //       // const response = await fetch("https://ipinfo.io/json");
  //       console.log(response);
  //       if (!response.ok) {
  //         console.log(response);
  //         throw new Error("Network response was not ok");
  //       }

  //       const data = await response.json();
  //       // setIpInfo(data);
  //     } catch (error) {
  //       console.error("Error fetching IP information:", error);
  //     }
  //   };

  //   fetchIpInfo();
  // }, []); // Empty dependency array ensures useEffect runs once on mount
  // console.log({ ipInfo });
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
