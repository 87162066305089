import React from "react";
import treeStructure from "../assets/images/howItWorks.png";

const HowItWorks = () => {
  return (
    <div id="howItWorks" className="bg-[#E0524F]">
      <div className="bg-gradient-to-r from-[#FEEBDF] via-[#FEEBDF] to-[#FEEBDF] h-32 relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 280"
          className="absolute bottom-0 -mb-px"
        >
          <path
            fill="#E0524F"
            d="M0,224L60,213.3C120,203,240,181,360,192C480,203,600,245,720,240C840,235,960,181,1080,154.7C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container mx-auto flex flex-col justify-center mb-8 ">
        <h2 className="font-bold text-3xl text-white mx-auto">How It Works</h2>
        <div className="flex justify-center">
          <img className="sm:max-w-[748px]" src={treeStructure} alt="" />
        </div>
        <h3 className="text-lg text-center sm:mx-auto mx-2 my-10 text-white sm:w-[80%]">
          User tops up his Card/Band with money which is then used to make
          subsequent payments across F&B / Activities.
        </h3>
      </div>
      <div className="bg-gradient-to-r from-[#E0524F] via-[#E0524F] to-[#E0524F] sm:h-32 h-8 relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 280"
          className="absolute bottom-0 -mb-px"
        >
          <path
            fill="#FEEBDF"
            d="M0,224L60,213.3C120,203,240,181,360,192C480,203,600,245,720,240C840,235,960,181,1080,154.7C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default HowItWorks;
