import React from "react";
import treeStructure from "../assets/images/howItWorks.png";

const OurServices = () => {
  return (
    <div id="ourservices" className="bg-[#E0524F]">
      <div className="bg-gradient-to-r from-[#FEEBDF] via-[#FEEBDF] to-[#FEEBDF] md:h-32 h-8 relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 280"
          className="absolute bottom-0 -mb-px"
        >
          <path
            fill="#E0524F"
            d="M0,224L60,213.3C120,203,240,181,360,192C480,203,600,245,720,240C840,235,960,181,1080,154.7C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container mx-auto flex flex-col justify-center mb-8 ">
        <h2 className="font-extrabold text-3xl text-white mx-auto">
          Our Services
        </h2>
        <section className="text-white body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex justify-center flex-wrap sm:-m-4 sm:mb-10 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center  mb-5">
                  <i className=" fa fa-4x fa-database "></i>
                </div>
                <div className="flex-grow">
                  <h2 className="text-xl title-font font-bold mb-3">
                    Collect More Data
                  </h2>
                  <p className="leading-relaxed text-base">
                    Our access credentials are impossible to forge or duplicate.
                    The best have tried and failed.
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div class="w-20 h-20 inline-flex items-center justify-center mb-5  flex-shrink-0">
                  <i className="fa fal fa-4x fa-line-chart"></i>
                </div>
                <div class="flex-grow">
                  <h2 class="text-xl title-font font-bold mb-3">
                    Increase Revenue
                  </h2>
                  <p class="leading-relaxed text-base">
                    Boost your transaction potential 6x by cutting transaction
                    times from ~90 seconds to ~15 seconds.
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center mb-5 text-white flex-shrink-0">
                  <i className="fa fa-4x fa-shopping-cart"></i>
                </div>
                <div className="flex-grow">
                  <h2 className="text-xl title-font font-bold mb-3">
                    Reconcile Accurately
                  </h2>
                  <p className="leading-relaxed text-base">
                    Track every purchase made so you can assign vendor splits
                    properly.
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center  mb-5 flex-shrink-0">
                  <i className="fa fa-4x fa-shield"></i>
                </div>
                <div className="flex-grow">
                  <h2 className="text-xl title-font font-bold mb-3">
                    Secure Your Event
                  </h2>
                  <p className="leading-relaxed text-base">
                    Protect access to certain or all areas to only the people
                    that should be inside.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center flex-wrap sm:-m-4 sm:mb-10 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
                  <i className="fa fa-4x fa-ticket"></i>
                </div>
                <div className="flex-grow">
                  <h2 className="text-xl title-font font-bold mb-3">
                    Stamp Out Ticket Fraud
                  </h2>
                  <p className="leading-relaxed text-base">
                    Our access credentials are impossible to forge or duplicate.
                    The best have tried and failed.
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
                  <i className="fa fa-4x fa-star"></i>
                </div>
                <div className="flex-grow">
                  <h2 className="text-xl title-font font-bold mb-3">
                    Enhance The Guest Experience
                  </h2>
                  <p className="leading-relaxed text-base">
                    Create a seamless guest experience that separates your event
                    from the rest.
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center  mb-5 flex-shrink-0">
                  <i className="fa fa-4x fa-users"></i>
                </div>
                <div className="flex-grow">
                  <h2 className="text-xl title-font font-bold mb-3">
                    Get People In Quicker
                  </h2>
                  <p className="leading-relaxed text-base">
                    A single Access Control portal can process 2,500 entries per
                    hour, significantly reducing wait times.
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/4 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center  mb-5 flex-shrink-0">
                  <i className="fa fa-4x fa-dollar"></i>
                </div>
                <div className="flex-grow">
                  <h2 className="text-xl title-font font-bold mb-3">
                    Manage Vendors Transparently
                  </h2>
                  <p className="leading-relaxed text-base">
                    Have full line-of-sight on every dollar your guests spend
                    and into vendor sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-gradient-to-r from-[#E0524F] via-[#E0524F] to-[#E0524F] h-16 relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 280"
          className="absolute bottom-0 -mb-px"
        >
          <path
            fill="#FEEBDF"
            d="M0,224L60,213.3C120,203,240,181,360,192C480,203,600,245,720,240C840,235,960,181,1080,154.7C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default OurServices;
