import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/images/AtomX-orange-e1660801634237.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Ref for the mobile menu drawer
  const menuRef = useRef(null);

  // Function to close the mobile menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Close the mobile menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div
      id="header"
      className="text-gray-600 body-font flex  sticky px-2 pt-2 top-0 left-0 right-0 justify-between bg-[#FEEBDF] z-10 shadow"
    >
      <div className="container mx-auto flex justify-between  bg-[#FEEBDF]   md:py-6 py-2 flex-row items-center">
        <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0 w-[156px] h-[60px]">
          <img src={logo} alt="AtomX" />
        </a>
        {/* Desktop Header */}
        <nav className="hidden lg:flex flex-wrap items-center text-lg text-black font-normal justify-center">
          <a href="#home" className="mr-10 hover:text-[#57D7FF]">
            home
          </a>
          <a href="#howItWorks" className="mr-10 hover:text-[#57D7FF]">
            how it works
          </a>
          <a href="#whatwedo" className="mr-10 hover:text-[#57D7FF]">
            what we do
          </a>
          <a href="#ourservices" className="mr-10 hover:text-[#57D7FF]">
            our services
          </a>
          <a
            href="#contact"
            className="hidden lg:inline-flex items-center bg-[#E0524F] border-0 py-[11px] px-[29px] text-white font-bold focus:outline-none hover:bg-white hover:text-[#E0524F] rounded-lg text-lg mt-4 md:mt-0"
          >
            Contact Us
          </a>
        </nav>
        {/* Mobile Header */}
        <div
          className="m-0 p-0 flex  lg:hidden cursor-pointer"
          onClick={toggleMenu}
        >
          <i className=" fa fa-2x fa-bars "></i>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg> */}
        </div>
        {/* Mobile Menu Drawer */}
        {isMenuOpen && (
          <div
            ref={menuRef}
            className="lg:hidden absolute top-0 right-0  pb-8 bg-[#FEEBDF]  w-screen flex flex-col items-center"
          >
            <button
              onClick={closeMenu}
              className="absolute top-0 right-0 pt-2 pr-4 text-gray-700 cursor-pointer"
            >
              <i className=" fa fa-2x fa-times"></i>
              {/* <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg> */}
            </button>
            <a
              href="#home"
              onClick={closeMenu} // Close menu on link click
              className="mt-8 hover:text-[#57D7FF]"
            >
              home
            </a>
            <a
              href="#howItWorks"
              onClick={closeMenu} // Close menu on link click
              className="mt-4 hover:text-[#57D7FF]"
            >
              how it works
            </a>
            <a
              href="#whatwedo"
              onClick={closeMenu} // Close menu on link click
              className="mt-4 hover:text-[#57D7FF]"
            >
              what we do
            </a>
            <a
              href="#ourservices"
              onClick={closeMenu} // Close menu on link click
              className="mt-4 hover:text-[#57D7FF]"
            >
              our services
            </a>
            <a
              href="#contact"
              onClick={closeMenu} // Close menu on link click
              className="mt-8 inline-flex items-center bg-[#E0524F] border-0 py-[11px] px-[29px] text-white font-bold focus:outline-none hover:bg-white hover:text-[#E0524F] rounded-lg text-lg"
            >
              Contact Us
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
