import React, { useEffect, useState } from "react";
import lda from "../assets/images/LDA1.webp";

const LDA = () => {
  return (
    <div
      id="lda"
      className="container mx-auto flex flex-col md:mt-0 mt-16 md:my-8 justify-center"
    >
      <section className="flex mx-2 md:mb-0 mb-4 align-middle flex-col md:flex-row justify-between">
        <div className="flex flex-col justify-center md:w-[50%]">
          <h2 className="mb-4 text-3xl font-extrabold">
            Legal Drinking Age Verification
          </h2>
          <h4 className="mb-8 text-justify text-bold md:text-lg text-base  md:pr-2 px-2">
            Verify Drinking Age at your night club or bar effortlessly with
            AtomX LDA (Legal Drinking Age) Validation. This ensures only legal
            adults enter your venue. The LDA App Verifies in real time with
            Aadhar database without any delays making verification a breeze. No
            more fake IDs, avoid underage drinking and driving at all cost. The
            LDA app by AtomX abides by the GDPR (General Data Protection
            Regulation) compliances and protects your customer data. No personal
            data of guest is saved or shared in the entire process.
          </h4>
        </div>
        <div className="md:max-w-[555px] md:pl-2 flex justify-center">
          <img src={lda} alt="chart" />
        </div>
      </section>
    </div>
  );
};

export default LDA;
