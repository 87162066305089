import React, { useEffect, useState } from "react";
import chart from "../assets/images/whatwedo.webp";
import tap from "../assets/images/whatwedo2.webp";
import smoothTxn from "../assets/images/whatwedo3.webp";
// import { useInView } from "react-intersection-observer";
// import numWords from "num-words";

const WhatWeDo = () => {
  // const [transactionAmount, setTransactionAmount] = useState(0);
  // const [totalTransactions, setTotalTransaction] = useState(0);
  // const [fansActivated, setFansActivated] = useState(0);
  // const [totalEvents, setTotalEvents] = useState(0);
  // const [number, setNumber] = useState(0);

  // const [ref, inView] = useInView();
  // const [number, setNumber] = useState(0);

  // const [word, setWord] = useState("");
  // const targetNumber = 3.5; // The target number

  // useEffect(() => {
  //   const delay = 100; // Delay for counting effect
  //   const wordDelay = 0.1; // Delay for generating the word

  //   const updateNumber = () => {
  //     if (number < targetNumber) {
  //       setNumber(number + 0.1); // Increment by 1000 to achieve the counting effect
  //     }
  //   };

  //   const updateWord = () => {
  //     if (word.length < 7) {
  //       setWord((prevWord) => prevWord + "million"[prevWord.length]);
  //     }
  //   };

  //   const numberInterval = setInterval(updateNumber, delay);
  //   const wordInterval = setInterval(updateWord, wordDelay / 7);

  //   return () => {
  //     clearInterval(numberInterval);
  //     clearInterval(wordInterval);
  //   };
  // }, [number, targetNumber, word]);

  // useEffect(() => {
  //   if (inView) {
  //     const targetNumber = 42; // Change this to your desired number
  //     const delay = 1000; // Change this to control the duration of the animation

  //     const updateNumber = () => {
  //       if (number < targetNumber) {
  //         setNumber(number + 1);
  //       }
  //     };

  //     const interval = setInterval(updateNumber, delay / (targetNumber + 1));

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [number, inView]);
  return (
    <div
      id="whatwedo"
      className="container mx-auto flex flex-col justify-center my-8"
    >
      <h2 className="font-extrabold text-3xl mx-auto">What We Do</h2>
      <section className="body-font">
        <div className="container md:py-24 py-12 md:mx-auto">
          <div className="flex flex-wrap md:flex-row flex-col justify-center items-center text-center">
            <div className="p-4 sm:w-1/4 ">
              <h2 className="title-font font-bold sm:text-3xl text-2xl ">
                $41 million+
              </h2>
              <p className="leading-relaxed text-xl">Transaction Amount</p>
            </div>
            <div className="p-4 sm:w-1/4">
              <h2 className="title-font font-bold sm:text-3xl text-3xl ">
                5.5 million+
              </h2>
              <p className="leading-relaxed text-xl">Total Transactions</p>
            </div>
            <div className="p-4 sm:w-1/4">
              <h2 className="title-font font-bold sm:text-3xl text-3xl ">
                820k+
              </h2>
              <p className="leading-relaxed text-xl">Fans Activated</p>
            </div>
            <div className="p-4 sm:w-1/4">
              <h2 className="title-font font-bold sm:text-3xl text-3xl">
                1600+
              </h2>
              <p className="leading-relaxed text-xl">Total Events</p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex md:mx-16 mx-2 md:mb-0 mb-4 align-middle flex-col md:flex-row justify-center">
        <div className="flex flex-col justify-center md:w-[50%]">
          <h2 className="mb-4 text-3xl font-extrabold">
            Drive Revenue With Cashless Payments
          </h2>
          <h4 className="mb-8 text-justify px-2 text-bold md:text-lg text-base">
            Event-goers spend up to 30% more when using cashless payments,
            faster transactions mean more sales. AtomX can integrate with
            virtually any cashless payment system, so you’re able to take
            advantage of lightning-fast contactless transactions.
          </h4>
        </div>
        <div className="md:max-w-[555px]">
          <img src={chart} alt="chart" />
        </div>
      </section>
      <section className="flex md:flex-row flex-col-reverse mx-2  md:mx-16">
        <div className="md:max-w-[555px]">
          <img src={tap} alt="tap&pay" />
        </div>
        <div className="flex flex-col justify-center md:w-[50%]">
          <h2 className="md:mb-4 my-8 text-3xl font-extrabold">
            Take Payments With A Tap
          </h2>
          <h4 className="text-bold text-justify px-2 md:text-lg text-base">
            Give your customers a faster way to pay for things. All they do is
            tap and go! With cashless integration, you can be ready to accept
            every sale whether your customers pay with their cash, debit cards
            or mobile devices. It’s a simple way to connect vendors with your
            attendees whilst remaining completely touchless.
          </h4>
        </div>
      </section>
      <section className="flex md:flex-row flex-col  md:mx-16 mx-2 align-middle justify-center">
        <div className="flex flex-col justify-center md:w-[50%]">
          <h2 className="mb-4 text-3xl font-extrabold">
            Smooth, Secure Transactions Guaranteed
          </h2>
          <h4 className="text-bold text-justify px-2 text-lg">
            Tapping to pay with a contactless chip card, mobile or wearable
            device includes an anti-fraud threshold so you can set to ensure
            maximum transaction safety. A cashless integration allows you to
            remove cash handling and improve your money’s traceability so you
            can protect your events against fraud losses and unauthorised
            transactions.
          </h4>
        </div>
        <div className="md:max-w-[555px]">
          <img src={smoothTxn} alt="transaction" />
        </div>
      </section>
    </div>
  );
};

export default WhatWeDo;
