import React, { useEffect, useState } from "react";
import eduX from "../assets/images/Pay.webp";
import eduXlogo from "../assets/images/EDUXlogo.webp";

const EduX = () => {
  return (
    <div
      id="lda"
      className="container mx-auto flex flex-col md:mt-0 mt-16 md:my-8 justify-center"
    >
      {/* <h2 className="mb-4 text-3xl font-extrabold">
        Legal Drinking Age Verification
      </h2> */}
      <div className="w-32 ml-4">
        <img src={eduXlogo} alt="chart" />
      </div>
      <section className="flex mx-2 md:mb-0 mb-4 align-middle flex-col md:flex-row justify-between">
        <div className=" md:max-w-[555px] md:pl-2 flex justify-center">
          <img src={eduX} alt="chart" />
        </div>
        <div className="flex flex-col justify-center md:w-[50%]">
          <h4 className="my-4 text-justify text-bold md:text-lg text-base  md:pr-2 px-2">
            EduX is a cutting-edge cashless campus solution powered by AtomX,
            designed to streamline financial transactions and access control in
            educational institutions. With the EduX NFC card, students can
            easily pay for meals, stationery, laundry, and other campus services
            with a simple tap. The system allows parents to monitor and control
            their child’s spending through a dedicated mobile app, providing
            peace of mind and easy recharging options. For campus management,
            EduX offers robust tracking, monitoring, and reporting capabilities,
            ensuring a secure, efficient, and fully cashless environment. EduX
            seamlessly integrates with existing campus systems, enhancing
            convenience for students, parents, vendors, and
            administrators alike.
          </h4>
        </div>
      </section>
    </div>
  );
};

export default EduX;
