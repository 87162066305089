import React from "react";
import mobile from "../assets/images/mobile-image.webp";
import wristband from "../assets/images/atomx-wristband2-1.webp";
import accessControl from "../assets/images/access-control-1-1.png";
import analysis from "../assets/images/analysis.png";
import paymentMethod from "../assets/images/payment-method.png";

const Home = () => {
  return (
    <div
      id="home"
      className="container  mx-auto md:pt-[60px] px-2 sm:pb-20 flex md:flex-row flex-col justify-between"
    >
      <div className="flex flex-col justify-between md:w-[70%]">
        <div className="md:w-[90%]">
          <h1 className="md:text-6xl text-4xl  font-extrabold mb-6 md:mb-10">
            Unlock The Power Of Cashless Payments
          </h1>
          <h3 className="md:text-2xl text-justify text-xl pb-4 md:pb-0 md:w-[80%]">
            Adapt the way your events make and take payments with cashless
            integration
          </h3>
        </div>
        <div className="flex flex-wrap xl:-m-0">
          <div className="md:w-1/3 p-2 ">
            <div className="border border-gray-200 p-6 rounded-lg text-white bg-[#103F5E]">
              <div className="w-10 h-10 inline-flex items-center justify-center mb-4">
                <img src={paymentMethod} alt="" className="" />
              </div>
              <h2 className="text-lg font-bold title-font mb-2">
                Cashless Payment
              </h2>
              <p className="leading-relaxed text-justify text-sm">
                Our portable readers is future-proof Near Field Communication
                oriented point of sale along with secure billing and order
                management. Just tap and pay.
              </p>
            </div>
          </div>
          <div className="md:w-1/3 p-2">
            <div className="border border-gray-200 p-6 rounded-lg text-white bg-[#E0524F]">
              <div className="w-10 h-10 inline-flex items-center justify-center  mb-4">
                <img src={analysis} alt="" className="" />
              </div>
              <h2 className="text-lg font-bold  title-font mb-2">
                Data Analytics
              </h2>
              <p className="leading-relaxed text-justify  text-sm">
                Get coherent, unprecedented real time data about your event and
                make data driven decisions for your next event to maximize your
                profit and revenue.
              </p>
            </div>
          </div>
          <div className="md:w-1/3 p-2 ">
            <div className="border p-6 rounded-lg text-white bg-[#103F5E]">
              <div className="w-10 h-10 inline-flex items-center justify-center  mb-4">
                <img src={accessControl} alt="" className="" />
              </div>
              <h2 className="text-lg font-bold title-font mb-2">
                Access Control
              </h2>
              <p className="leading-relaxed text-justify  text-sm">
                Eliminate ticket fraud, secure sensitive areas, and collect
                real-time traffic flow information — all while getting your
                guests in faster and safer with Access Control.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[30%]">
        <img src={mobile} alt="mobile" />
        <img
          className="w-[50%] xl:-mt-[300px] xl:ml-[250px] -mt-[250px] ml-[180px] z-10"
          src={wristband}
          alt="wristband"
        />
      </div>
    </div>
  );
};

export default Home;
